import React from 'react'
import { Link } from 'gatsby'
import queryString from 'query-string'
import * as yup from 'yup'

import { Alert, Seo } from '../components/common'
import { ClientOnly } from '../components/common/clientOnly'
import { Input } from '../components/common/form/fields'
import { Paths } from '../constants/structure'
import { AuthFlowForm, AuthFlowWrapper } from '../components/views/layout'
import { useForm, useResetPassword } from '../hooks'

const schema = yup.object().shape({
   password: yup
      .string()
      .required(
         'Your password requires at least 8 characters, minimum one upper case'
      ),
   confirmPassword: yup
      .string()
      .required('The confirm password field is required.')
      .oneOf(
         [yup.ref('password'), null],
         'The new password and confirm new password do not match.'
      ),
})

export default function PasswordResetPage({ location }) {
   // Gets token from the query params
   const { token, userId } = queryString.parse(location.search)
   // IMPORTANT URL should be set with %2B for + symbols. so they will become spaces once its decoded through the queryString.parse. To fix this we have to replace white space with a + symbol
   const newToken = token ? token.replaceAll(' ', '+') : null

   const { error, isLoading, isSuccess, submit } = useResetPassword()
   const { onSubmit, validationErrors } = useForm(schema, (values) => {
      submit({ ...values, token: newToken, userId })
   })

   const tokenErrorMsg =
      'Token & userId are not available in the URL, please check the email you received'
   const tokenError = !token || !userId ? tokenErrorMsg : null

   const errorToRender = error || tokenError

   const Layout1 = () => {
      return (
         <ClientOnly>
            <AuthFlowWrapper
               description="Please enter your new password below and again to confirm"
               heading="Password Reset"
            >
               <AuthFlowForm
                  button={{
                     label: 'Reset password',
                  }}
                  isLoading={isLoading}
                  onSubmit={onSubmit}
                  returnLink={<Link to={Paths.LOGIN}>Back to login</Link>}
               >
                  {!!errorToRender && <Alert message={errorToRender} />}

                  <Input
                     id="password"
                     inputProps={{
                        type: 'password',
                     }}
                     label="New Password"
                     required
                     theme="dark"
                     error={validationErrors?.password}
                  />

                  <Input
                     id="confirmPassword"
                     inputProps={{
                        type: 'password',
                     }}
                     label="Confirm Password"
                     required
                     theme="dark"
                     error={validationErrors?.confirmPassword}
                  />
               </AuthFlowForm>
            </AuthFlowWrapper>
         </ClientOnly>
      )
   }
   const Layout2 = () => {
      return (
         <AuthFlowWrapper
            description=" Your password has been updated. You can now login with your new password."
            heading="Password reset"
         >
            <AuthFlowForm
               returnLink={<Link to={Paths.LOGIN}>Back to Login</Link>}
            />
         </AuthFlowWrapper>
      )
   }

   return (
      <>
         <Seo title="Password reset" />
         {!isSuccess ? Layout1() : Layout2()}
      </>
   )
}
